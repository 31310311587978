import React from "react";
import { Helmet } from "react-helmet";
import { useAuthState } from "@myaccountServices";

const PageHeaderHelmet = ({theme}) => {
    // Use the google font definition from the theme file
    const googleFont = theme.getProp("googleFont");
    const { state } = useAuthState();
    let pageTitle = ` My Account Portal | ${process.env.GATSBY_MANIFEST_NAME}`;
    if (state?.pageTitle)
        pageTitle = `${state?.pageTitle} | ${process.env.GATSBY_MANIFEST_NAME}`;

    return(
        <Helmet>
            {googleFont && <link rel="preconnect" href="https://fonts.googleapis.com" />}
            {googleFont && <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />}
            {googleFont && <link href={googleFont} rel="stylesheet" />}
            <title>{pageTitle}</title>
            <meta name="description" content="" />
        </Helmet>
    )
}

export default PageHeaderHelmet;
